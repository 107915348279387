<template>
  <div class="auth-wrapper auth-v2" :style="backgroundStyle">
    <b-spinner v-if="isLoading" type="grow" class="mt-5 mx-auto d-block" />
    <b-row v-else class="auth-inner m-0" align-v="center">
      <!-- Login-->
      <b-col
        cols="12"
        md="6"
        offset-md="3"
        class="d-flex align-items-center my-0 p-0 col"
      >
        <b-card class="px-3 px-md-2 mx-auto my-md-3 my-0 auth-bg w-100">
          <b-link
            :to="{ name: 'auth-signup' }"
            class="bg-light px-1 rounded py-75"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="14"
              class="mr-25"
            />
            <span>{{ $t('backoffice.back') }}</span>
          </b-link>
          <safe-img :src="communityLogo" class="login-logo mb-3 mt-2 round" />
          <h1 class="font-weight-bold w-100 text-center mb-3" title-tag="h1">
            {{ translate(community.name) }}
          </h1>
          <b-card-text class=" mb-2 text-center h3">
            Términos y condiciones
          </b-card-text>
          <div v-if="text" v-html="text[currentLocale] || Object.values(text)[0]" />
          <b-card-group v-else style="text-align:justify; padding:20px;">
            <h2 data-v-35bd2db0="">
              1. INFORMACIÓN LEGAL
            </h2>
            <b-card-text data-v-35bd2db0="">
              Las presentes Condiciones Generales de Uso y Contratación (en adelante las “Condiciones”) regulan la
              adquisición y el uso de los productos y servicios que {{ translate(community.name) }} (según dicho término se define
              a continuación) pone a disposición de sus usuarios y/o clientes, ya sea a través de acceso Web, en el
              portal de Internet <a data-v-35bd2db0="" href="https://nectios.com/">https://nectios.com/</a> (en
              adelante, el “Sitio Web” o la “Web” indistintamente), a través de dispositivos iOS o Android, en línea o
              fuera de línea, así como a través de la aplicación de escritorio (en adelante, la “Solución”). La
              utilización de la Web y de la Solución le atribuye la condición de usuario (en adelante, el “Usuario”) y
              la contratación de los Servicios, tal y como éstosse definen más adelante, le atribuye la condición de
              cliente (en adelante, el “Cliente”) y ambos implican la aceptación de las presentes Condiciones. Algunos
              servicios o funcionalidades de la Solución pueden estar sometidos a condiciones particulares que, en su
              caso, modifican o complementan las presentes Condiciones y que se entenderán aceptadas por el Usuario y/o
              el Cliente al inicio de la prestación de dicho servicio o de la utilización de la correspondiente
              funcionalidad (en adelante, las Condiciones Particulares”).{{ translate(community.name) }} se reserva el derecho de
              modificar, en cualquier momento y sin previo aviso, el contenido y configuración de la Web y de la
              Solución, así como de los servicios ofrecidos. Los Usuariosy Clientes deben leer atentamente las presentes
              Condiciones. Al hacer clic indicando su aceptación, al aceptar una Orden de Compra de los Servicios (tal y
              como estos términosse definen más adelante) o al utilizar servicios gratuitos, el Cliente y/o el Usuario
              aceptan las presentes Condiciones que rigen el uso de los Servicios. Así mismo, el registro o uso de la
              Solución implica la íntegra aceptación de las presentes Condiciones, así como de las políticas de
              privacidad de {{ translate(community.name) }} incluidas en
              <a data-v-35bd2db0="" href="http://nectios.com/legal-center/my/?legal=privacy_policy">http://nectios.com/my/legal-center/</a>
              (en adelante, la “Política de Privacidad”). Por último, en el supuesto de que un Cliente contrate los
              Servicios a través de un distribuidor autorizado de {{ translate(community.name) }}, le serán de aplicación las
              presentes Condiciones Generales, con excepción de los aspectos relativos a pago, facturación,
              notificaciones y, en su caso, servicios de asistencia técnica que, en virtud del acuerdo comercial
              existente entre el distribuidor y el Cliente, no le son de aplicación.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              2. SERVICIOS
            </h2>
            <h3 data-v-35bd2db0="">
              2.1 Descripción de los Servicios
            </h3>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} pone a disposición del Cliente, a través de la Solución, un conjunto de servicios de
              gestión de equipos comerciales descrito en detalle en la dirección
              <a data-v-35bd2db0="" href="http://nectios.com">http://nectios.com</a> o en otra URL similar que pudiera
              proporcionar {{ translate(community.name) }} (en adelante, los “Servicios”). Los Servicios se ofrecen en línea a
              través de la aplicación Web, a través de dispositivos móviles, tanto fuera de línea como en línea. La
              Solución permitirá el uso de una interfaz basada en diferentes aplicaciones tablet, web y móvil, así como
              el cifrado, la transmisión, el acceso y el almacenamiento de datos. Los Servicios se ofrecen en varias
              ediciones y paquetes que pueden diferir notablemente en sus funcionalidades. Consulte nuestro sitio Web
              para conocer las diferencias entre las distintas ediciones. No se puede acceder a ni utilizar los
              Servicios con el fin de controlar su disponibilidad, rendimiento o funcionalidad, ni para ningún otro
              propósito de evaluación comparativa o fin competitivo
            </b-card-text>
            <h3 data-v-35bd2db0="">
              2.2 Datos en la prestación de los Servicios
            </h3>
            <b-card-text data-v-35bd2db0="">
              La Solución permite al Cliente procesar la información basada en (i) los horarios de actividad comercial
              del Usuario, (ii) su ubicación geográfica, (iii) las comunicaciones recibidas o enviadas por un Usuario,
              (iv) fecha, duración e interlocutor de las llamadas telefónicas recibidas o realizadas, (v) los comandos
              de voz enviados a la Solución, (vi) fotografías facilitadas por el Usuario, (vii) detalles de las
              vídeollamadas mantenidas a través de soluciones integradas en la plataforma, como por ejemplo la hora,
              duración y contenido de dichas llamadas, que podrá incluir la grabación íntegra de las mismas,
              independientemente de que se hayan mantenido con la opción de vídeo (cámara) activado o no; así como otra
              información relacionada con su actividad comercial y que encontrará actualizada en todo momento en la
              Política de Privacidad de {{ translate(community.name) }} en el link
              <a
                data-v-35bd2db0=""
                href="https://nectios.com/es/info/privacidad/"
              >https://nectios.com/es/info/privacidad/</a>

              (en adelante los “Datos de los Usuarios”). El Cliente, y en su caso el Usuario, podrá darse de baja de
              estas funcionalidades en cualquier momento mediante la edición de la configuración a nivel de dispositivo
              o poniéndose en contacto con {{ translate(community.name) }}. Por contra, el Cliente podrá activar nuevas
              funcionalidades poniéndose en contacto con {{ translate(community.name) }}, bajo un nuevo presupuesto, debiendo
              informar de ello al Usuario. Así mismo, y para la efectiva prestación de los Servicios y funcionamiento de
              la Solución, el Cliente y/o cada Usuario podrán enviar a la Solución aquellos datos, informaciones o
              materiales que consideren oportuno, incluyendo datos, informaciones o materiales de terceros, en especial
              los clientes o clientes potenciales del Cliente (estos últimos, en adelante, los “Datos de Terceros”). Los
              Datos de los Usuarios y los Datos de Terceros serán denominados conjuntamente como los “Datos”. A efectos
              aclaratorios, los Datos incluirán cualquier dato volcado a la Solución (exceptuando los datos de contacto
              y facturación del propio Cliente). En cualquier caso, los datos enviados a la aplicación por parte del
              Cliente y/o el Usuario, deberán cumplir con las presentes Condiciones y Política de Privacidad.
              {{ translate(community.name) }} sólo compartirálos Datos en los términos indicados en la Política de Privacidad. Así
              mismo, el Cliente declara y garantiza que tiene la correspondiente autorización para gestionar los Datos
              de Terceros mediante el uso de la Solución. Mediante la marcación de la casilla habilitada a tales
              efectos, el Cliente acepta recibir periódicamente por parte de {{ translate(community.name) }}, comunicaciones de
              marketing relativas a los productos y servicios de {{ translate(community.name) }}, así como newsletters de
              {{ translate(community.name) }} con noticias y novedades sobre ventas,tecnología y el sector del Cliente. El Cliente
              podrá notificar a {{ translate(community.name) }}, en cualquier momento y por correo electrónico a la dirección
              <a data-v-35bd2db0="" :href="email">newsletter{{ translate(community.name) }}.tech</a> , su deseo de no recibir una o
              ambas comunicaciones.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              2.3 Modificaciones
            </h3>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} podrá realizar los cambios, modificaciones y ajustes que considere razonables en los
              Servicios cuando lo estime oportuno. En caso de realizar un cambio sustancial en los Servicios,
              {{ translate(community.name) }} deberá comunicarlo previamente al Cliente.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              3. OBLIGACIONES DEL CLIENTE
            </h2>
            <h3 data-v-35bd2db0="">
              3.1 Cumplimiento de las Condiciones
            </h3>
            <b-card-text data-v-35bd2db0="">
              Por la presente, el Cliente y el Usuario se obligan a utilizar los Servicios de acuerdo con los términos
              establecidos en las presentes Condiciones. {{ translate(community.name) }} puede ofrecer, según considere oportuno,
              aplicaciones, características o funciones adicionales a través de los Servicios, cuyo uso puede estar
              supeditado a Condiciones Particulares que deberán ser aceptadas por el Cliente y, en su caso, por el
              Usuario.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              3.2 Condiciones de uso y límites
            </h3>
            <b-card-text data-v-35bd2db0="">
              El Cliente y el Usuario cumplirán con las condiciones de uso y límites relativos al uso de los Servicios
              que establezca {{ translate(community.name) }} en cada momento, incluidos, sin limitaciones: (i) requisitos técnicos
              y de implementación de la Solución; (ii) directrices de tratamiento de las marcas titularidad de
              {{ translate(community.name) }} para los Servicios; (iii) términos de uso y política de privacidad del Sitio Web y de
              la Solución y (iv) requisitos de {{ translate(community.name) }} para protección de datos y seguridad.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              3.3. Uso permitido y restricciones de uso
            </h3>
            <b-card-text data-v-35bd2db0="">
              El Cliente y el Usuario aceptan utilizar los Servicios exclusivamente de conformidad con las presentes
              Condiciones y la legislación aplicable. Asimismo, el Cliente y el Usuario se comprometen a no involucrarse
              en ninguna actividad que interfiera con los Servicios o losservidores o redes conectados a los Servicios o
              que pueda interrumpirlos. Además de regirse por estas Condiciones, el uso de determinados servicios
              específicos de {{ translate(community.name) }} por parte del Cliente estará regido por las Condiciones Particulares
              que se presentan al Cliente cuando éste se registra o accede a los Servicios en cuestión, incorporándose
              específicamente las mismas en el momento de aceptación por parte del Cliente.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              A menos de que {{ translate(community.name) }} lo acuerde específicamente por escrito, ni el Cliente ni el Usuario
              podrán llevar a cabo ninguna de las acciones que se describen a continuación, debiendo el Cliente
              asegurarse de que el Usuario cumpla con lo aquí especificado. Asimismo, tanto el Cliente como el Usuarios
              deberán realizar todos los esfuerzos razonables para asegurarse de que ningún tercero las lleve a cabo:
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              (i) utilizar la Solución de forma que pueda dañar, deshabilitar, sobrecargar o deteriorar la misma, ni
              utilizarla de forma que pueda interferir en el uso y disfrute de la Solución por parte de terceros; (ii)
              eliminar, ocultar o alterar avisos de derechos de autor, marcas u otros avisos de derechos de propiedad,
              que contenga la Solución;(iii) enviar correos basura o mensajes duplicados o no deseados que infrinjan la
              normativa aplicable; (iv) enviar o almacenar material ilícito, obsceno, amenazante, difamatorio, ilegal o
              agravioso, incluido material perjudicial para los niños o que infrinja los derechos de privacidad de
              terceros; (v) enviar o almacenar material que contenga software con virus, gusanos, troyanos o códigos,
              archivos, secuencias de comandos, agentes o programas informáticos dañinos; (vi) interferir o afectar a la
              integridad o rendimiento de los Servicios o la Solución, o los datos que contiene; o (vii) intentar
              obtener acceso no autorizado a la Solución o los sistemas o redes relacionados.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              3.4 Administración de los Servicios por parte del Cliente
            </h3>
            <b-card-text data-v-35bd2db0="">
              El Cliente recibirá un nombre y una contraseña que deberá utilizar a efectos de administración de las
              cuentas de Cliente. El Cliente tiene la responsabilidad de mantener la confidencialidad de dicha
              contraseña y cuenta, para lo cual designará a los empleados que dispondrán de autorización para acceder a
              la cuenta y limitará el alcance de dicha autorización al cumplimiento de las tareas asignadas y para todas
              las actividades que se realicen en el marco de la cuenta del Cliente. El Cliente acepta notificar de forma
              inmediata a {{ translate(community.name) }} sobre cualquier uso o acceso no autorizado a los Servicios, la Solución,
              a la contraseña o cuenta del Cliente o cualquier otro tipo de vulneración de la seguridad.
              {{ translate(community.name) }} no podrá responsabilizarse ni se responsabilizará de pérdida o daño alguno derivado
              del incumplimiento de las obligaciones de seguridad por parte del Cliente. El Cliente reconocey acepta que
              {{ translate(community.name) }} no se responsabilizará en forma alguna ni bajo circunstancia alguna de ninguna acción
              u omisión por parte del Cliente o de cualquier Usuario, incluidos los daños de cualquiertipo resultantes
              de dichas acciones u omisiones.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              3.5. Responsabilidad del Cliente y consentimiento del Usuario
            </h3>
            <b-card-text data-v-35bd2db0="">
              El administrador del Cliente puede tener la capacidad de acceder, supervisar, utilizar o divulgar los
              Datos de los Usuarios y los Datos de Terceros dentro de las cuentas de los Usuarios vinculados al Cliente,
              configurándose el Cliente como responsable del tratamiento de estos datos. Es responsabilidad exclusiva
              del Cliente cumplir con cuantas obligaciones se deriven de su condición de responsable del tratamiento,
              con entera indemnidad para {{ translate(community.name) }}.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Así mismo, el Cliente y el Usuario son conocedores de que, en el supuesto que el Cliente haya dado de alta
              esta funcionalidad, cualquier Usuario vinculado con el Cliente puede acceder y supervisar cualquier dato
              dentro de las cuentas de cualquier otro Usuario vinculado con el mismo Cliente, ya que cualquier Usuario
              vinculado con el Cliente puede tener visibilidad de las cuentas de los demás Usuarios vinculados con el
              mismo Cliente. El Cliente garantiza que cuenta con el consentimiento expreso de los Usuarios para permitir
              dicha funcionalidad. El Cliente, y en su caso el Usuario, podrá darse de baja de estas funcionalidades en
              cualquier momento mediante la edición de la configuración a nivel de dispositivo o poniéndose en contacto
              con {{ translate(community.name) }}.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              3.6. Uso no autorizado
            </h3>
            El Cliente llevará a cabo sus mejores esfuerzos para impedir el uso no autorizado de los Servicios y para
            cancelar usos no autorizados. El Cliente informará de inmediato a {{ translate(community.name) }} de cualquier uso o
            acceso no autorizado al Servicio del que tenga conocimiento.
            <b-card-text data-v-35bd2db0="" />
            <h3 data-v-35bd2db0="">
              3.7. Condiciones aplicables al Usuario
            </h3>
            <b-card-text data-v-35bd2db0="">
              El Cliente reconoce que será responsable de toda actividad que se produzca en su cuenta de Cliente y que
              sus Usuarios están sujetos a las presentes Condiciones por lo que respecta a cada uno de los componentes
              de los Servicios, así como a las Condiciones Particulares que puedan ser de aplicación a determinados
              Servicios.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              En el caso de que el Cliente tenga noticia de alguna infracción de las presentes Condiciones o de las
              Condiciones Particulares en aquellos extremos aplicables a los Usuarios, o de otras condiciones de los
              Servicios o políticas aplicables por parte de un Usuario, el Cliente acepta (i) notificárselo a
              {{ translate(community.name) }} tan pronto como sea posible; y (ii) suspender o cancelar de manera inmediata la
              cuenta de dicho Usuario a menos que {{ translate(community.name) }} consienta lo contrario por escrito (incluso a
              través del correo electrónico). A petición de {{ translate(community.name) }}, el Cliente suspenderá o cancelará de
              forma inmediata cualquier Cuenta del Usuario o acceso de administrador al Servicio como respuesta a una
              infracción de los términos de uso o política aplicables por parte de un Usuario. {{ translate(community.name) }}
              se reserva el derecho, a su discreción, de suspender o cancelar la Cuenta de Usuario de cualquier Usuario.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              3.8. Información y datos de la cuenta
            </h3>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} no es propietario de los datos, informaciones o materiales que envíe el Cliente y/o
              el Usuario a los Servicios y la Solución durante su uso (los “Datos de los Usuarios y los Datos de
              Terceros”). El Cliente, y no {{ translate(community.name) }}, será el único responsable de la exactitud, calidad,
              integridad, legalidad, fiabilidad, idoneidad y derechos de propiedad intelectual y/o industrial en el uso
              de los Datos y {{ translate(community.name) }} no será responsable de la eliminación, corrección, destrucción, daño,
              pérdida o error surgido durante el almacenamiento de los Datos, y en general, de cualquier dato subido a
              la Solución.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Siempre que el Cliente lo solicite en el momento de la resolución de la contratación de los Servicios,
              {{ translate(community.name) }} pondrá a disposición del Cliente un archivo con los Datos (titularidad del Cliente)
              durante los treinta (30) días posteriores a la extinción de los Servicios
            </b-card-text>
            <h3 data-v-35bd2db0="">
              3.9. Interacción con terceros
            </h3>
            <b-card-text data-v-35bd2db0="">
              Durante el uso de los Servicios, el Cliente puede compartir correspondencia, adquirir bienes o servicios o
              participar en promociones de anunciantes o patrocinadores que muestren sus bienes y/o servicios a través
              de los Servicios.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Cualquiera de estas actividades y cualquier condición, manifestación o garantía asociada a dichas
              actividades es exclusiva entre el Cliente y dichos terceros. {{ translate(community.name) }} y sus distribuidores no
              tienen responsabilidad u obligación alguna derivada de dicha correspondencia, adquisición o promoción
              entre el Cliente y dichos terceros.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} no respalda ningún sitio de Internet al que se acceda a través de los Servicios.
              {{ translate(community.name) }} ofrece estos enlaces al Cliente para su comodidad y en ningún caso
              {{ translate(community.name) }} o susdistribuidores serán responsables del contenido, los productos u otros
              materiales disponibles en dichos enlaces.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} ofrece los Servicios al Cliente de conformidad con las presentes Condiciones y,
              cuando corresponda, conforme a las Condiciones Particulares. No obstante, el Cliente admite que otros
              proveedores de software, hardware o servicios adicionales pueden requerir la aceptación por parte del
              Cliente de otra licencia diferente o adicional u otras condiciones antes de poder utilizar o acceder a
              dicho software, hardware o servicios.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              4. FACTURACIÓN Y PAGO
            </h2>
            <h3 data-v-35bd2db0="">
              4.1 Periodos de prueba gratuita
            </h3>
            <b-card-text data-v-35bd2db0="">
              En aquellas contrataciones a las que se apliquen periodos iniciales de prueba gratuitos conforme a lo
              dispuesto en la Orden de Compra (según dicho término se define en la condición 4.2 siguiente) no se
              realizará ningún cargo mientras dure dicho periodo. Si el Cliente decide cancelar los Servicios antes de
              finalizar el periodo de prueba, no se le realizará ningún cargo.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              4.2 Forma de pago
            </h3>
            <b-card-text data-v-35bd2db0="">
              Todos los pagos se realizarán en la forma indicada en la orden de compra de servicios aceptada por el
              Cliente (en adelante, la “Orden de Compra”). El Cliente realizará el pago de los importes indicados en la
              Orden de Compra (en adelante, los “Importes”).
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              LosImportes, una vezfacturados, deberán abonarse al principio del periodo en el que el Cliente recibe los
              Servicios y con la periodicidad acordada en la Orden de Compra. No se podrán cancelar las obligaciones de
              pago ni reembolsar las cantidades ya pagadas. El Cliente tiene la obligación de pagar todas las licencias
              de Usuario solicitadas para todo el periodo de licencia establecido en la Orden de Compra (el “Periodo de
              Licencia”), tanto si las mismas están en uso como si no.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              4.3 Facturas
            </h3>
            <b-card-text data-v-35bd2db0="">
              El Cliente se obliga a ofrecer información completa y precisa de facturación y de contacto a
              {{ translate(community.name) }}. Esta información incluye el nombre de la compañía, número de cuenta corriente,
              tarjeta de crédito (en su caso) u otra forma de pago aplicable, dirección postal, dirección de correo
              electrónico, nombre y número de teléfono de un contacto autorizado para la facturación, el número de
              identificación del impuesto sobre el valor añadido intracomunitario (en su caso) y datos del administrador
              del Cliente.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              El Cliente se compromete a actualizar esta información en un plazo de diez (10) días tras cualquier
              modificación. Si la información de contacto que ha ofrecido esfalsa, fraudulenta o no está actualizada,
              {{ translate(community.name) }} se reserva el derecho de cancelar el acceso al Servicio, así como de emprender
              cualquier otra medida legal que estime conveniente
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Si el Cliente considerase que su factura no es correcta, deberá ponerse en contacto con
              {{ translate(community.name) }}o su distribuidor por escrito, en un período máximo de siete (7) días a partir de la
              fecha de dicha factura, para revisar conjuntamente dicha factura y, en su caso, poder recibir una
              rectificación
            </b-card-text>
            <h3 data-v-35bd2db0="">
              4.4 Modificación del número de licencias
            </h3>
            <b-card-text data-v-35bd2db0="">
              El Cliente podrá solicitar licencias adicionales en cualquier momento, que estarán sujetas a las presentes
              Condiciones y, en su caso, a las Condiciones Particulares.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Asimismo, el Cliente podrá reducir el número de licencias para Usuarios mediante la remisión de un correo
              electrónico a {{ translate(community.name) }} con una antelación mínima de treinta (30) días hábiles antes de lafecha
              del siguiente Período de Licencia.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              4.5 Modificación del plan de Servicios
            </h3>
            <b-card-text data-v-35bd2db0="">
              En el caso que el Cliente solicite una mejora (o upgrade) de su actual plan de Servicios, las condiciones
              específicas aplicables como consecuencia de dicha mejora serán las establecidas en la Orden de Compra y
              será prorrateada proporcionalmente por el período restante del Periodo de Licencia.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Por el contrario, el Cliente no podrá solicitar una reducción (o downgrade) de su plan de Servicios
              durante el Periodo de Licencia, siendo únicamente aplicable una vez finalizado dicho Periodo de Licencia.
              La solicitud de una reducción deberá realizarse también mediante la remisión de un correo electrónico a
              {{ translate(community.name) }} con una antelación mínima de treinta (30) días hábiles antes de la finalización del
              Período de Licencia.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              4.6. Tarifas adicionales
            </h3>
            <h4 data-v-35bd2db0="">
              4.6.1. Tarifa adicional de almacenamiento de datos
            </h4>
            <b-card-text data-v-35bd2db0="">
              El espacio de almacenamiento de datos que {{ translate(community.name) }} le ofrece de forma gratuita al Cliente es
              de1GB en base de datos para el conjunto de Licencias de Usuario que el Cliente tenga contratadas en
              cualquier edición o paquete de {{ translate(community.name) }} y 5MB adicionales por cada Licencia de Usuario de
              cualquier edición o paquete de {{ translate(community.name) }}.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Si la cantidad de almacenamiento de disco requerida por el Cliente superara los límites establecidos en el
              párrafo anterior, {{ translate(community.name) }} se reserva el derecho de cobrar al Cliente la tarifa de
              almacenamiento vigente en ese momento. {{ translate(community.name) }} intentará comunicar al Cliente, en la medidade
              lo posible, cuando el almacenamiento medio utilizado por licencia alcance el noventa por ciento (90%)
              aproximadamente del límite indicado. No obstante, si {{ translate(community.name) }} no le notifica esta situación,
              ello no exime al Cliente de su responsabilidad y obligación de pago ante los cargos de almacenamiento de
              datos adicionales
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} se reserva el derecho de establecer o modificar en cualquier momento, su proceder
              habitual y los límites en relación con el almacenamiento de datos. {{ translate(community.name) }} publicará en su
              página web su política de almacenamiento de datos y los límites vigentes en cada momento.
            </b-card-text>
            <h4 data-v-35bd2db0="">
              4.6.2. Tarifa adicional de almacenamiento de archivos
            </h4>
            <b-card-text data-v-35bd2db0="">
              El espacio de almacenamiento de archivos que {{ translate(community.name) }} le ofrece de forma gratuita al Cliente
              esde 2,5GB para el conjunto de Licencias de Usuario que el Cliente tenga contratadas en cualquier edicióno
              paquete de {{ translate(community.name) }} y 250MB adicionales por cada Licencia de Usuario de cualquier edición o
              paquete de {{ translate(community.name) }}.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Si la cantidad de almacenamiento de archivos requerida por el Cliente superara los límites establecidos en
              el párrafo anterior, {{ translate(community.name) }} se reserva el derecho de cobrar al Cliente la tarifa de
              almacenamiento vigente en ese momento. {{ translate(community.name) }} intentará comunicar al Cliente, en la medidade
              lo posible, cuando el almacenamiento medio utilizado por licencia alcance el noventa por ciento (90%)
              aproximadamente del límite indicado. No obstante, si {{ translate(community.name) }} no le notifica esta situación,
              ello no exime al Cliente de su responsabilidad y obligación de pago ante los cargos de almacenamientode
              archivos adicionales.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} se reserva el derecho de establecer o modificar en cualquier momento, su proceder
              habitual y los límites en relación con el almacenamiento de archivos. {{ translate(community.name) }} publicará en su
              página web su política de almacenamiento de archivos y los límites vigentes en cada momento.
            </b-card-text>
            <h4 data-v-35bd2db0="">
              4.6.3. Tarifa adicional de servicios de geolocalización
            </h4>
            <b-card-text data-v-35bd2db0="">
              El volumen de peticiones de geolocalización que {{ translate(community.name) }} ofrece de forma gratuita al Cliente
              esde 20.000 al mes por el conjunto de Licencias de Usuario que el Cliente tenga contratadas en cualquier
              edición o paquete de {{ translate(community.name) }}.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Si el volumen de peticiones de geolocalización requerido por el Cliente superara los límites establecidos
              en el párrafo anterior, {{ translate(community.name) }} se reserva el derecho de cobrar al Cliente la tarifa de
              geolocalización vigente en ese momento. {{ translate(community.name) }} intentará comunicar al Cliente, en la
              medidade lo posible, cuando el volumen de peticiones de geolocalización utilizado por el Cliente alcance
              el noventa por ciento (90%) aproximadamente del límite indicado. No obstante, si {{ translate(community.name) }} no
              le notifica esta situación, ello no exime al Cliente de su responsabilidad y obligación de pago ante los
              cargos de geolocalización adicionales
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} se reserva el derecho de establecer o modificar en cualquier momento, su proceder
              habitual y los límites en relación con las peticiones de geolocalización. {{ translate(community.name) }} publicará
              en su página web su política de peticiones de geolocalización y los límites vigentes en cada momento.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              4.7 Impago
            </h3>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} se reserva el derecho a suspender o extinguir las presentes Condiciones y, en su
              caso,las Condiciones Particulares que resulten de aplicación y, por consiguiente, el acceso a los
              Servicios por parte del Cliente si incurre en retrasos en los pagos por un período superior a diez (10)
              días desde su vencimiento.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Las cuentas con retrasos en los pagos están sujetas a un interés mensual del 1,5% aplicable sobre el saldo
              pendiente, además de todos los gastos necesarios para su cobro. Este interés no podrá ser fraccionado y un
              solo día de retraso implicará la aplicación de la totalidad de los intereses.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Si el Cliente o {{ translate(community.name) }} finalizan el acceso al Servicio, el Cliente estará obligado a abonar
              el saldo pendiente de su cuenta.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} se reserva el derecho a imponer una tarifa de reconexión en caso de que el Cliente
              hayasido suspendido y posteriormente haya solicitado el acceso al Servicio.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              5. IMPUESTOS
            </h2>
            <b-card-text data-v-35bd2db0="">
              Los importes establecidos por los Servicios no incluyen impuestos, tasas u otros recargos similares de
              ninguna naturaleza, incluidos sin limitarse a ellos, impuestossobre el valor añadido, de ventas, de uso o
              retenciones, establecidos por la legislación aplicable (en forma conjunta, “Impuestos”). El Cliente será
              responsable del pago de los Impuestos y no podrá realizar pagos a {{ translate(community.name) }} por los Servicios
              con deducciones de Impuestos incluidas.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              6. SUSPENSIÓN E INTERRUPCIÓN DE LOS SERVICIOS
            </h2>
            <h3 data-v-35bd2db0="">
              6.1 De Cuentas de Usuario por parte de {{ translate(community.name) }}
            </h3>
            <b-card-text data-v-35bd2db0="">
              En caso de incumplimiento de las presentes Condiciones por el Cliente, o en el evento en que, previo
              requerimiento por parte de {{ translate(community.name) }}, el Cliente no hubiera adoptado las medidas necesarias
              para su cumplimiento en un plazo de diez (10) días, {{ translate(community.name) }} se reserva el derecho a suspender
              los Servicios, sin perjuicio de lo establecido en el apartado 12 de las presentes Condiciones.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Adicionalmente, si {{ translate(community.name) }} tiene conocimiento de algún incumplimiento de las presentes
              Condiciones por parte del Usuario, podrá solicitar al Cliente la suspensión de la Cuenta de Usuario en
              cuestión. En caso de que el Cliente no satisfaga dicha solicitud de {{ translate(community.name) }}, será
              {{ translate(community.name) }}
              quien podrá llevar a cabo la suspensión de la Cuenta de Usuario. La duración de cualquier suspensiónse
              prolongará hasta que el Usuario en cuestión haya subsanado el incumplimiento causante de la misma.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              6.2 Disponibilidad y Acceso a los Servicios.
            </h3>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} proporcionará disponibilidad y acceso continuo a los Servicios, salvo en los eventos
              enlos que, por circunstancias ajenas a su control, deban realizarse suspensiones o interrupciones de los
              mismos, que serán debidamente informadas al Cliente. Dichas interrupciones podrán deberse a terceros de
              los que depende la prestación de los Servicios, tales como los prestadores de servicios de hosting y de
              firma digital, entre otros proveedores de servicios externos. En estos casos {{ translate(community.name) }}no
              incurrirá en responsabilidad alguna como consecuencia de la suspensión o interrupción de los Servicios.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} realiza sus mejores esfuerzos para llevar a cabo las tareas de mantenimiento y
              mejoraen la Solución de modo que no suponga la interrupción de los Servicios. Sin embargo, para el caso
              enque para llevar a cabo dichas tareas sea necesaria la interrupción temporal de los Servicios,
              {{ translate(community.name) }} notificará con antelación a los Clientes y a los Usuarios de la fecha prevista para
              realizardichas tareas y la duración estimada de las mismas. {{ translate(community.name) }} no incurrirá en
              responsabilidad alguna como consecuencia de la suspensión o interrupción de los Servicios para la
              realización de tareas de mantenimiento y mejora en la Solución siempre que haya informado oportunamente a
              los Clientesy los Usuarios conforme a lo dispuesto en este apartado.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              6.3 Por Incidencias de seguridad de urgencia
            </h3>
            <b-card-text data-v-35bd2db0="">
              Si existe una incidencia de seguridad de urgencia, {{ translate(community.name) }} podrá proceder a la suspensión
              automática del uso de la cuenta de Usuario. La suspensión tendrá la duración y el alcance mínimos
              necesarios para evitar o resolver la incidencia de seguridad de urgencia. Si, por cualquier motivo,
              {{ translate(community.name) }} suspende una cuenta de Usuario sin avisar previamente al Cliente,
              {{ translate(community.name) }} indicará el motivo de la suspensión al Cliente tan pronto como sea razonablemente
              posible, si así lo requiere este último.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              7. DERECHOS DE PROPIEDAD INTELECTUAL Y/O INDUSTRIAL
            </h2>
            <b-card-text data-v-35bd2db0="">
              A excepción de lo establecido de forma expresa en el presente documento, las presentes Condiciones no
              garantizan a ninguna de las partes ningún derecho, implícito o no, sobre el contenido o sobre cualquier
              derecho de propiedad intelectual y/o industrial de la otra parte. En este sentido,
              {{ translate(community.name) }} posee todos los derechos de propiedad intelectual y/o industrial de los Servicios.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} y sus proveedores mantendrán la propiedad de todo derecho, título e interés
              incluyendo, de forma no limitativa, todos los derechos de propiedad intelectual y/o industrial (en virtud
              de lo definido más adelante) relacionados con los Servicios y la Solución, así como de cualquier trabajoo
              mejora derivados de ésta, incluyendo, sin ánimo limitativo, cualquier software, tecnología, información,
              contenido, materiales, directrices y documentación. Ni los Clientes ni los Usuarios adquirirán derecho,
              título, interés o contenido alguno, exceptuando los derechos de uso limitado establecidos de forma expresa
              en las presentes Condiciones y/o en las Condiciones Particulares. Todo derecho que no se conceda
              expresamente en este documento se considerará no otorgado.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} no es propietario del contenido de terceros utilizado como parte de los Servicios,
              incluido el contenido de las comunicaciones que aparecen en los Servicios. El título y los derechos de
              propiedad intelectual y/o industrial del contenido al que se accede a través de los Servicios pertenecen
              al propietario del contenido en cuestión y pueden estar protegidos por la ley de propiedad intelectual u
              otras leyes aplicables.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              El Cliente acepta no llevar a cabo ni permitir a otros que lleven a cabo las siguientes acciones: (i)
              adaptar, traducir o modificar la Solución; (ii) descifrar, descompilar, desmontar, aplicar ingeniería
              inversa u otro tipo de intento de descubrir el código fuente o de objetos del software de la Solución,
              salvo si lasleyes aplicables prohíben dichaslimitaciones; (iii) copiar la Solución; (iv) utilizar los
              Servicios para actividades de alto riesgo; ni (v) otorgar bajo licencia, prestar, vender, alquilar o
              arrendar los Servicios o cualquier componente de éstos
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Cualquier código fuente de tercerosincluido en cada una de las aplicaciones de los Serviciossólo podrá
              utilizarse conjuntamente con dicha aplicación de los Servicios, cuyo uso estará sujeto a las presentes
              Condiciones y/o en las Condiciones Particulares.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              QUEDA PROHIBIDO UTILIZAR, COPIAR, TRANSFERIR Y MODIFICAR LA SOLUCIÓN (INCLUYENDO LOS DERECHOS DE PROPIEDAD
              INTELECTUAL Y/O INDUSTRIAL SOBRE LA MISMA) O CUALQUIER PARTE DE LA MISMA, EXCEPTO EN LOS CASOS EN QUE LAS
              PRESENTES CONDICIONES LO PERMITAN DE FORMA EXPRESA.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              7.1 Marcas y títulos distintivos
            </h3>
            <b-card-text data-v-35bd2db0="">
              A los efectos de las presentes Condiciones y de las Condiciones Particulares que puedan ser de aplicación,
              se entienden por “Marcas y Títulos Distintivos” los nombres comerciales, marcas comerciales, marcas de
              servicio, logotipos, nombres de dominio y otros elementos de marca distintivos de cada parte,
              respectivamente, protegidos en cada momento por dicha parte. Ninguna de las partes puede mostrar o
              utilizar los Marcas y Títulos Distintivos de la otra más allá de lo permitido en estas Condiciones y/o en
              las Condiciones Particulares sin el consentimiento previo por escrito de la otra parte. Excepto en la
              medida en que se indique expresamente, ninguna de las partes concederá ni la otra adquirirá ningún
              derecho, título ni interés incluido, sin limitación, cualquier licencia implícita de cualquier Marcas y
              Títulos Distintivos de la primera parte. Todos los derechos que no se concedan expresamente se
              considerarán no otorgados. Todo uso de los Marcas y Títulos Distintivos del Cliente por parte de
              {{ translate(community.name) }}, se realizará en beneficio del Cliente; y todo uso de los Marcas y Títulos
              distintivos de {{ translate(community.name) }} por parte del Cliente, se realizará en beneficio de
              {{ translate(community.name) }}. El Cliente no infringirá ni colaborará con otros en la infracción de las Marcas y
              Títulos Distintivos de {{ translate(community.name) }} o el registro de los mismos, así como tampoco intentará
              registrar Marcas y Títulos Distintivos de {{ translate(community.name) }} o nombres de dominio muy parecidos a los de
              {{ translate(community.name) }} o que puedan inducir a equívocos.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              7.2 Limitación en el uso de Marcas y Títulos Distintivos
            </h3>
            <b-card-text data-v-35bd2db0="">
              Las partes podrán revocar el derecho de la otra parte a usar sus Marcas y Títulos Distintivos conforme a
              estas Condiciones mediante aviso por escrito a la otra parte, que tendrá que cesar inmediatamente en su
              uso.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              7.3 Software de código abierto (Open Source Software) y bibliotecas de terceros
            </h3>
            <b-card-text data-v-35bd2db0="">
              La Web y la Solución pueden incluir herramientas de software de código abierto o bibliotecas de terceros.
              El software de código abierto y/o las bibliotecas de terceros se regirán por sus propios términos y
              condiciones y el Usuario estará sujeto a los términos y condiciones de la licencia correspondiente en
              relación con el uso de dicho software o bibliotecas. El Usuario acepta cumplir con todas las mencionadas
              licencias.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              8. CONFIDENCIALIDAD
            </h2>
            <b-card-text data-v-35bd2db0="">
              Cada una de las partes (i) protegerá la información confidencial de la otra parte con las mismas medidas
              de protección estándar que utiliza para proteger su propia Información confidencial; y (ii) no divulgará
              la información confidencial, excepto a los empleados y representantes, en el caso exclusivo de que sea
              necesario y de que hayan aceptado por escrito mantener las obligaciones de confidencialidad. Cada una de
              las partes (y todos los empleados y representantes a los que se haya revelado información confidencial)
              debe utilizar la información confidencial únicamente para ejercersus derechos y cumplir las obligaciones
              establecidas en estas Condiciones y/o en las Condiciones Particulares; empleando medidas razonables para
              protegerla. Cada una de las partes es responsable de las acciones de sus empleados y representantes que
              infrinjan este apartado.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              8.1 Excepciones
            </h3>
            <b-card-text data-v-35bd2db0="">
              No se considera información confidencial aquella información (a) que ya sea conocida por el destinatario,
              (b) que se haga pública sin responsabilidad del destinatario, (c) que el destinatario genere de forma
              independiente o (d) que un tercero le transmita legítimamente.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              8.2 Divulgación necesaria
            </h3>
            <b-card-text data-v-35bd2db0="">
              Cada una de las partes puede divulgar la información confidencial de la otra parte cuando sea requerido
              por la ley, aunque, si la ley lo permite, solo después de que (i) realice todos los esfuerzos razonables
              para informar a la otra parte; y (ii) conceda a la otra parte la oportunidad de recusar la divulgación.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              9. PUBLICIDAD
            </h2>
            <b-card-text data-v-35bd2db0="">
              El Cliente y el Usuario aceptan no llevar a cabo ningún tipo de anuncio público en relación con la
              existencia o el contenido de las presentes Condiciones y/o las Condiciones Particulares sin la previa
              autorización por escrito por parte de {{ translate(community.name) }}. {{ translate(community.name) }} podrá (i) incluir las
              Marcas y Títulos Distintivos del Cliente en presentaciones, materiales de marketing y listas de clientes,
              que incluyen,sinlimitación, listas de clientes publicadas en los sitios web de {{ translate(community.name) }} y
              capturas de pantalla de laimplementación de los Servicios por parte del Cliente; y (ii) emitir un anuncio
              público en relación con laexistencia o el contenido de estas Condiciones. A petición del Cliente,
              {{ translate(community.name) }} proporcionará aéste una muestra de dicho uso o anuncio
            </b-card-text>
            <h2 data-v-35bd2db0="">
              10. DURACIÓN DE LOS SERVICIOS Y RENOVACIÓN
            </h2>
            <h3 data-v-35bd2db0="">
              10.1 Duración de los Servicios
            </h3>
            <b-card-text data-v-35bd2db0="">
              El plazo de cada Servicio será el especificado en la Orden de Compra correspondiente. Salvo que en la
              Orden de Compra se indique lo contrario, los Servicios se renovarán automáticamente por períodos
              adicionales equivalentes al Período de Licencia de la Orden de Compra inicial o un año (el que sea más
              corto), a menos que cualquiera de las partes lo comunique a la otra parte por escrito con una antelación
              mínima de treinta (30) días antes de la fecha fin del Período de Licencia vigente.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Salvo lo dispuesto expresamente en la Orden de Compra correspondiente, en la renovación de los Servicios
              contratados inicialmente bajo condiciones promocionales se aplicará el precio tarifa de
              {{ translate(community.name) }}
              vigente en el momento de la renovación correspondiente.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Sin perjuicio de lo anterior, cualquier renovación en la que el volumen de Usuarios de los Servicios
              contratados o la duración de cualquiera de ellos sea inferior al Período de Licencia anterior puede dar
              como resultado un nuevo precio por Usuario y Servicio, sin tener en cuenta el precio por Usuario del
              Período de Licencia anterior.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              10.2 Actualización y revisión de precios
            </h3>
            <b-card-text data-v-35bd2db0="">
              En caso de renovación de los Servicios contratados, {{ translate(community.name) }} podrá actualizar el precio de
              dichos Servicios al alza sin preaviso alguno en una cantidad equivalente al resultante de aplicar el
              índice de incremento del coste de vida a los precios aplicables durante el Período de Licencia anterior. A
              estos efectos, se tomará en cuenta el índice de incremento del coste de vida del país a cuya legislación
              queden sujetas las presentes Condiciones de conformidad con el Anexo I de las mismas.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} puede revisar sus precios para el siguiente Periodo de Licencia de los Servicios por
              encima de los importes resultantes del párrafo anterior, siempre que medie notificación por email al
              Cliente al menos treinta (30) días antes del inicio del Periodo de Licencia de los Servicios en el que se
              deba aplicar dicha revisión de precios.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              10.3 Devoluciones y prorrateo
            </h3>
            <b-card-text data-v-35bd2db0="">
              En ningún caso se prorratearán Periodos de Licencia y no se realizarán devoluciones de losimportes ya
              pagados por el Cliente.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              11.PERÍODO DE VIGENCIA Y TERMINACIÓN DE LAS CONDICIONES
            </h2>
            <h3 data-v-35bd2db0="">
              11.1 Periodo de vigencia de las Condiciones
            </h3>
            <b-card-text data-v-35bd2db0="">
              Estas Condiciones tendrán validez desde su aceptación por primera vez por el Cliente o el Usuario o el uso
              de Servicios gratuitos por el Usuario conforme a lo indicado en la condición 1 de estas Condiciones y
              continúa hasta que todos los Servicios hayan expirado o hayan sido cancelados conforme a lo indicado en
              estas Condiciones. Sin perjuicio de lo anterior, las condiciones 7, 8, 9, 12 y 16, y los apartados de la
              condición 3 que por su naturaleza deban seguir vigentes, seguirán vigentes una vez finalizado el período
              de vigencia de las Condiciones.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              En el caso de las pruebas gratuitas, las notificaciones emitidas a través de los Servicios que indican el
              número de días restantes de la prueba gratuita constituyen el aviso por {{ translate(community.name) }} de
              terminaciónde la vigencia de estas Condiciones.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Cualquier incumplimiento de las obligaciones contraídas por el Cliente en virtud de las presentes
              Condiciones incluyendo, pero no limitándose a, el impago, pago o uso no autorizado de la Solución,
              software, producto o de los Servicios de {{ translate(community.name) }}, supondrá un incumplimiento material de las
              presentes Condiciones. En caso de incumplimiento de estas Condiciones por parte del Cliente,
              {{ translate(community.name) }}, bajo criterio propio, podrá suspender o cancelar la prestación de los Servicios al
              Cliente, suspender los Usuarios y vinculados al Cliente y sus contraseñas.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              11.2 Terminación por incumplimiento
            </h3>
            <b-card-text data-v-35bd2db0="">
              Cada una de las partes puede suspender los Servicios y dar por terminadas esta Condiciones si (i) la otra
              parte incumple de forma sustancial las presentes Condiciones y no subsana el incumplimiento antes de 30
              días después de la recepción de la notificación por escrito de la parte no incumplidora, (ii) la otra
              parte cesa sus operaciones empresariales o se ve sometida a procedimientos de insolvencia y dichos
              procedimientos no se desestiman antes de noventa días o (iii) la otra parte incumple las presentes
              Condiciones de forma sustancial más de dos veces, sin perjuicio de que se subsanen dichos incumplimientos.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              11.3 Efectos de la terminación
            </h3>
            <b-card-text data-v-35bd2db0="">
              En caso de terminación (i) los derechos otorgados por una parte a la otra cesarán con efecto inmediato
              (con excepción de los derechos otorgados en virtud de las condicionesindicadas en el apartado 10.1.), (ii)
              {{ translate(community.name) }} proporcionará al Cliente acceso a los Datos, de su propiedad, así como la
              posibilidadde solicitar la exportación de los mismos, durante un periodo de treinta (30) días y de
              conformidad conlos precios de {{ translate(community.name) }} vigentes en ese momento para los Servicios aplicables,
              en caso de ser ellos necesarios, (iii) tras el mismo período de treinta (30) días una vez finalizados los
              servicios, {{ translate(community.name) }} eliminará los Datos en los términos indicados en la Política de
              Privacidad, (iv) cada una de las partes realizará de inmediato todos los esfuerzos razonables para
              devolver o destruir cualquier otra Información confidencial de la otra parte, si así se solicita, y (v) no
              eximirá al Cliente del pago de los Importes debidos y no abonados a {{ translate(community.name) }} hasta el fin del
              Período de Licencia o de cualquiera de sus prórrogas
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Asimismo, {{ translate(community.name) }} puede cancelar una cuenta gratuita en cualquier momento sin notificación
              previa a su entera discreción. El Cliente acepta que {{ translate(community.name) }} no será responsable frente al
              Cliente ni frente a terceros por motivo de dicha cancelación. El Cliente será el único responsable de
              exportar los Datos de la cuenta gratuita antes de la finalización del acceso a la misma por parte del
              Cliente por cualquier motivo. A pesar de lo anterior, en el supuesto en que sea {{ translate(community.name) }} quién
              finalice la cuenta gratuita a su entera discreción, le dará oportunidad al Cliente para recuperar
              previamente susDatos
            </b-card-text>
            <h2 data-v-35bd2db0="">
              12. DECLARACIONES, GARANTÍAS Y LIMITACIÓN DE RESPONSABILIDAD
            </h2>
            <h3 data-v-35bd2db0="">
              12.1 Declaraciones y garantías
            </h3>
            <b-card-text data-v-35bd2db0="">
              Cada parte manifiesta que dispone de la capacidad jurídica necesaria para suscribir las presentes
              Condiciones. Cada parte garantiza que cumplirá con todas las leyes y normativas aplicables a la prestación
              o al uso de los Servicios,según corresponda. El Cliente garantiza que cumplirá con todaslas leyes,
              regulaciones y ordenanzas aplicables o relacionadas con el uso que haga de los Servicios y que toda la
              información facilitada a {{ translate(community.name) }} es veraz y cierta.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              12.2 Garantíaslimitadas
            </h3>
            <b-card-text data-v-35bd2db0="">
              En la medida en que la ley lo permita, a menos de que quede expresado de otro modo en las presentes
              Condiciones, ninguna de las partes ofrece ninguna otra garantía de ningún tipo, implícita ni explícita,
              obligatoria ni de otra clase incluidas, sin limitarse a ello, las garantías de comerciabilidad, adecuación
              para un fin particular y no infracción. {{ translate(community.name) }} no se hace responsable del contenido ni de la
              información a la que se pueda acceder a través de los Servicios.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              El Cliente reconoce y acepta que cada uno de los Servicios puede presentar errores, defectos y otros
              problemas que podrían provocar un fallo del sistema. En consecuencia, los Servicios, incluido todo el
              contenido,software (incluida cualquier actualización o modificación del mismo), funciones, materiales e
              información puestos a disposición o a los que se acceda a través de los Servicios, así como toda
              documentación que los acompañe, se proporcionan “tal cual”, por lo que todo uso que se haga de estos será
              responsabilidad única del Cliente. {{ translate(community.name) }} y sus proveedores de aplicaciones integradas con
              la Solución no ofrecen garantía de ningún tipo, implícita ni explícita, obligatoria ni de otra clase
              incluidas, sin limitación, las garantías de comerciabilidad, adecuación para un fin particular y de no
              infracción. {{ translate(community.name) }} no asume ningún tipo de responsabilidad derivada del uso correcto de los
              Servicios. {{ translate(community.name) }} y sus proveedores de aplicaciones integradas con la Solución no emiten
              declaraciones relacionadas con ningún contenido ni información accesible a través de los Servicios.
              {{ translate(community.name) }} no emite ninguna declaración de que {{ translate(community.name) }}, o ningún tercero, publicará
              actualizaciones o mejoras de los Servicios. {{ translate(community.name) }} no garantiza que las funciones incluidas
              en los Servicios no se verán interrumpidas ni que carezcan de errores.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              12.3 Limitación de responsabilidad
            </h3>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} NI SUS PROVEEDORES DE APLICACIONES INTEGRADAS CON LA SOLUCIÓNNO SE RESPONSABILIZARÁN
              DE NINGÚN DAÑO DIRECTO O INDIRECTO INCLUIDOS, SIN LIMITACIONES, LOS DAÑOS CAUSADOS POR LA INTERRUPCIÓN DEL
              USO O POR LA PÉRDIDA DE DATOS O LA PRESENCIA DE DATOS IMPRECISOS O DAÑADOS, PÉRDIDA DE BENEFICIOS O GASTOS
              DEL ABASTECIMIENTO DE BIENESO SERVICIOS SUSTITUTIVOS, SEA CUAL SEA SU CAUSA, INCLUIDOS, SIN LIMITACIÓN, EL
              USO, MAL USO, INCAPACIDAD DE USO O USO INTERRUMPIDO, NI BAJO NINGUNA TEORÍA DE RESPONSABILIDAD INCLUIDOS,
              SIN LIMITACIÓN, CONTRATOS O AGRAVIOS E INDEPENDIENTEMENTE DE SI {{ translate(community.name) }} TENÍA O DEBERÍA HABER
              TENIDO CONOCIMIENTO O HABER SIDO ADVERTIDO DE LAPOSIBILIDAD DE ESTOS DAÑOS, AL MARGEN DE SI CUALQUIER
              REMEDIO ESPECIFICADO EN LAS PRESENTES CONDICIONES NO CUMPLIERA SU FINALIDAD ESENCIAL; O DE NINGUNA
              RECLAMACIÓN QUE ALEGUE DAÑOS DERIVADOS DE ERRORES, OMISIONES U OTRAS IMPRECISIONES DE LOS SERVICIOS O LAS
              PROPIEDADES DE LOS MISMOS.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              12.4 Limitación de lasresponsabilidadesindirectas
            </h3>
            <b-card-text data-v-35bd2db0="">
              NINGUNA DE LAS PARTES SERÁ RESPONSABLE POR DAÑO EMERGENTE, LUCRO CESANTE NI DAÑO INDIRECTO.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              12.5 Limitación del importe por responsabilidad
            </h3>
            <b-card-text data-v-35bd2db0="">
              Las Partes acuerdan limitar la responsabilidad derivada de cualquier daño o incumplimiento imputable a
              {{ translate(community.name) }} al importe efectivamente pagado por el Cliente según lo estipulado en estas
              Condiciones, durante los seis (6) meses anteriores al evento que ha dado lugar a dicha responsabilidad.
              {{ translate(community.name) }} no otorga garantía alguna por el uso de Servicios que no estén sujetos a
              contraprestación o durante el periodo de prueba.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              13. {{ translate(community.name) }} COMO ENCARGADO DEL TRATAMIENTO DE LOS “DATOS”
            </h2>
            <b-card-text data-v-35bd2db0="">
              La captación y tratamiento de datos personales realizados a través de nuestro Sitio Web y a través de la
              prestación de los Servicios aplicables a las presentes Condiciones se rige por lo dispuesto en nuestra
              Política de Privacidad y Política de Cookies que quedan incorporadas a los presentesTérminos y
              Condiciones.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} sólo es responsable en cuanto al tratamiento de los datos de contacto y facturación
              delos Clientes.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Por lo que se refiere a los Datos, a los oportunos efectos, detallamos a continuación la información
              básica sobre protección de datos:
            </b-card-text>
            <div data-v-35bd2db0="" class="table">
              <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
                <div data-v-35bd2db0="" class="col-lg-3">
                  Encargado del Tratamiento
                </div>
                <div data-v-35bd2db0="" class="col-lg-9">
                  Empresa {{ translate(community.name) }} Technologies SL
                </div>
              </div>
              <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
                <div data-v-35bd2db0="" class="col-lg-3">
                  Finalidad del Tratamiento
                </div>
                <div data-v-35bd2db0="" class="col-lg-9">
                  Gestionar la prestación de servicios
                </div>
              </div>
              <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
                <div data-v-35bd2db0="" class="col-lg-3">
                  Base jurídica
                </div>
                <div data-v-35bd2db0="" class="col-lg-9">
                  La Solución de {{ translate(community.name) }} permite a los Clientes procesar los “Datos”, por lo quela base
                  jurídica para que la empresa contratante trate dichos datos como encargado del tratamiento es la
                  necesidad de prestar los Servicios conforme a las presentes Condiciones Generales de Uso y
                  Contratación (art. 6.1 b) GDPR).
                </div>
              </div>
              <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
                <div data-v-35bd2db0="" class="col-lg-3">
                  Destinatarios
                </div>
                <div data-v-35bd2db0="" class="col-lg-9">
                  Se podrán ceder datos a otras empresas del grupo {{ translate(community.name) }}, en la medida necesaria para
                  prestar los Servicios y para el cumplimiento de obligacioneslegales. <br data-v-35bd2db0="">
                  Se pueden usar encargados de tratamiento fuera de la UE, en países con las garantías adecuadas o
                  cumpliendo con los requerimientos legales en cada momento.
                </div>
              </div>
              <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
                <div data-v-35bd2db0="" class="col-lg-3">
                  Derechos
                </div>
                <div data-v-35bd2db0="" class="col-lg-9">
                  Acceder, rectificar y suprimir sus datos, solicitar la portabilidad de los mismos, oponerse al
                  tratamiento y solicitar la limitación de éste.
                </div>
              </div>
            </div>
            <h2 data-v-35bd2db0="">
              14. SOPORTE
            </h2>
            <b-card-text data-v-35bd2db0="">
              La Licencia de Usuario incluye un servicio de soporte básico a prestar por {{ translate(community.name) }} al Cliente
              conforme al servicio soporte básico que corresponda a la edición o paquete de la Solución que el Cliente
              haya contratado (en adelante el “Servicio de Soporte”).
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              A dichos efectos, el Cliente y el administrador del Cliente podrán ponerse en contacto con losservicios de
              asistencia técnica proporcionados por {{ translate(community.name) }}, mediante los canales habilitados para ello
              encada momento, resultando en un caso (“ticket”) que será gestionado por {{ translate(community.name) }} hasta su
              cierre.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} atenderá y responderá a las incidencias o consultas enviando mensajes de correo
              electrónico a la dirección de correo electrónico y/o contactando al número de teléfono que el Cliente
              tenga asignados en su Cuenta de Usuario.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              El Servicio de Soporte es de carácter correctivo, es decir, está orientado a la corrección de incidencias
              en el funcionamiento del producto estándar, es decir, la versión base de la Solución, común a cualquier
              contratación por parte de un cliente, y sobre la cual se efectuarán las personalizaciones contratadas por
              cada cliente (en adelante el “Producto Standard”). El Servicio de Soporte no gestiona incidencias
              producidas en las personalizaciones particulares del cliente realizadas por los equipos de servicios
              profesionales de {{ translate(community.name) }} o sus partners.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} dará asistencia técnica con respecto a las dos últimas versiones aprobadas de la
              Solución.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Además, {{ translate(community.name) }} podrá descatalogar partes o versiones de la Solución previa comunicación a
              los clientes con antelación suficiente y razonable para que éstos puedan adaptarse a los cambios. La
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              descatalogación de una parte o versión de la Solución supone que
              {{ translate(community.name) }} no dará soporte ni realizará acciones de mantenimiento evolutivo o correctivo sobre
              dicha parte o versiones.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              El Cliente podrá contratar a {{ translate(community.name) }} servicios de soporte más avanzados, que estarán sujetos
              alas presentes Condiciones Generales y a las Condiciones Particulares que les resulten de aplicación.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              15. SERVICIOS PROFESIONALES
            </h2>
            <b-card-text data-v-35bd2db0="">
              Las capacidades estándar de la Solución pueden ser extendidas para un cliente concreto por consultores
              especializados en la tecnología de la Solución, ya sean consultores de {{ translate(community.name) }} o partners
              certificados, dando como resultado un producto extendido que consta del Producto Estándar más sus
              personalizaciones.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Las personalizaciones, por ser partes de la Solución desarrolladas únicamente para un cliente, están
              sujetas a las condiciones de garantía, mantenimiento o soporte estipuladas por el implantador (prestador
              de servicios profesionales) que las haya realizado. El prestador de servicios profesionales podrá ser
              {{ translate(community.name) }} directamente o cualquiera de sus Partners certificados.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              Cada implementador de la Solución definirá los términos y condiciones aplicables a la prestación de
              servicios profesionales en un documento específico.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              16. INDEMNIZACIÓN
            </h2>
            <b-card-text data-v-35bd2db0="">
              El Cliente deberá indemnizar y mantener indemne a {{ translate(community.name) }} y sus sucursales, filiales,
              empresasdel grupo, proveedores, directores, accionistas, empleados, colaboradores y agentes, de cualquier
              reclamación o demanda de un tercero, en relación con (i) los Datos, (ii) cualquier infracción por parte
              del Cliente de los Derechos de propiedad intelectual y/o industrial de un tercero o (iii) un uso de los
              Servicios por parte del Cliente o del Usuario que infrinja lostérminos y condiciones de uso establecidas
              en las presentes Condiciones Generales.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              El Cliente tendrá que indemnizar a {{ translate(community.name) }} por las reclamaciones derivadas de cualquier
              incumplimiento surgido de la combinación de los Servicios con cualquier otro producto, servicio, hardware
              o proceso empresarial
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              En ningún caso {{ translate(community.name) }} tendrá obligación ni responsabilidad alguna, según se establece en
              este apartado, derivadas del (i) uso de los Servicios o elementos de marca de {{ translate(community.name) }} de
              forma modificada o en combinación con otros materiales no facilitados por {{ translate(community.name) }} y (ii)
              contenido, información o datos proporcionados por el Cliente, {{ translate(community.name) }}, Usuarios u otros
              terceros.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              17. POSIBLES INFRACCIONES
            </h2>
            <h3 data-v-35bd2db0="">
              17.1 Reparar,sustituir o modificar
            </h3>
            <b-card-text data-v-35bd2db0="">
              Si {{ translate(community.name) }} tiene sospechas razonables de que los Servicios infringen los Derechos de
              propiedad intelectual y/o industrial de un tercero, {{ translate(community.name) }} (a) obtendrá la autorización de
              uso por parte del tercero para el Cliente, a cargo de {{ translate(community.name) }}, para que pueda seguir usando
              los Servicios, (b) proporcionará un sustituto funcionalmente equivalente que no los infrinja o (c)
              modificará los Servicios para que la infracción no se vuelva a producir.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              17.2 Suspensión o rescisión
            </h3>
            <b-card-text data-v-35bd2db0="">
              Si {{ translate(community.name) }} considera que las opciones anteriores no son razonables, puede suspender o
              rescindirel uso de los Servicios afectados. Si {{ translate(community.name) }} rescinde los Servicios afectados,
              deberá proporcionar una devolución prorrateada de los Importes no devengados ya pagados por el Cliente
              aplicables al periodo siguiente a la rescisión de dicho Servicio.
            </b-card-text>
            <h2 data-v-35bd2db0="">
              18. OTRAS DISPOSICIONES
            </h2>
            <h3 data-v-35bd2db0="">
              18.1 Software de terceros
            </h3>
            <b-card-text data-v-35bd2db0="">
              A efectos aclaratorios se hace constar que las presentes Condiciones serán de aplicación al Cliente y/o al
              Usuario por lo que respecta al uso por parte de éstos de cualquier funcionalidad permitida por la Solución
              que sea proporcionada por un tercero proveedor. Es decir, las limitaciones de uso, obligaciones y
              prohibiciones contenidas en las presentes Condiciones con respecto a la Solución, lo son con respecto a
              cualquier software de tercero incluido en ésta.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.2. Modificación
            </h3>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} se reserva el derecho de cambiar o modificar cualquiera de las presentes
              Condiciones,así como toda política que rija los Servicios, en cualquier momento, mediante la publicación
              de las condiciones en <a data-v-35bd2db0="" href=" http://nectios.com "> http://nectios.com </a>o
              cualquier otra URL que {{ translate(community.name) }} proporcione. El Cliente es responsable de consultar con
              regularidad las posibles actualizaciones de las presentes Condiciones. Todos los cambios o modificaciones
              que se realicen serán vinculantes (i) cuando ambas partes los acepten por escrito, (ii) cuando el Cliente
              acepte online la actualización de los mismos o si (iii) una vez que {{ translate(community.name) }} actualice dichos
              términos, el Cliente sigue haciendo un uso continuadode los Servicios.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.3 Comunicaciones
            </h3>
            <b-card-text data-v-35bd2db0="">
              Todas las notificaciones y comunicaciones que procedan, derivadas de la relación contractual objeto de las
              presentes Condiciones se enviarán por escrito, pudiendo enviarse incluso por correo electrónico siempre
              que se pueda dejar constancia de su recepción. A dichos efectos el Cliente podrá enviar sus comunicaciones
              a la dirección de
              <a data-v-35bd2db0="" href="mailto:hello@nectios.com.">hello@nectios.com.</a>
              {{ translate(community.name) }} podrá enviar las comunicaciones a la dirección electrónica que el cliente haya
              notificado en la documentación relacionada con la venta de la Solución. Las Partes podrán varias las
              direcciones facilitadas, comunicándolo a la otra por escrito
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.4 Cesión
            </h3>
            <b-card-text data-v-35bd2db0="">
              El Cliente no podrá ceder ni transferir su posición contractual, ni los derechos y obligaciones asumidos
              en virtud de las presentes Condiciones, sin el consentimiento por escrito de {{ translate(community.name) }}, y en
              todocaso se deberá cumplir lo siguiente, que (a) el beneficiario acepte por escrito obligarse a las
              condiciones de las presentes Condiciones y (b) la parte que efectúa la cesión sigue siendo responsable de
              las obligaciones contraídas mediante las presentes Condiciones antes de dicha cesión. Cualquier otro
              intento de cesión se considerará nulo de pleno derecho.
            </b-card-text>
            <b-card-text data-v-35bd2db0="">
              {{ translate(community.name) }} tendrá derecho a prestar los Servicios directamente o a través de cualquiera de las
              compañías que forman parte del grupo {{ translate(community.name) }}, listadas en el Anexo I, listado que está
              actualizado en todo momento en la política de privacidad de {{ translate(community.name) }}, debiendo aceptar el
              Cliente la prestación de dichos Servicios por las filiales como si hubieran sido prestados por
              {{ translate(community.name) }}.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.5 Fuerza mayor
            </h3>
            <b-card-text data-v-35bd2db0="">
              Ninguna de las partes será responsable por el incumplimiento de las obligaciones establecidas en las
              presentes Condiciones, siempre y cuando esté causado por una circunstancia ajena al control razonable de
              la otra parte como, por ejemplo, desastres naturales, actos de guerra o terrorismo, disturbios, conflictos
              laborales, actuaciones del Gobierno y fallos de Internet.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.6 Vigencia de las condiciones
            </h3>
            <b-card-text data-v-35bd2db0="">
              Si alguna disposición de las presentes Condicionesse considera inaplicable, las disposicionesrestantes
              permanecerán en vigor.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.7 Inexistencia de representación
            </h3>
            <b-card-text data-v-35bd2db0="">
              Las partes contratantes son independientes y las presentes Condiciones no constituyen ninguna
              representación, asociación o empresa conjunta.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.8 Empresa contratante, ley y jurisdicción aplicable
            </h3>
            <b-card-text data-v-35bd2db0="">
              En Anexo I se detallan las empresas contratantes por parte del grupo
              {{ translate(community.name) }}, así como la ley y jurisdicción aplicable a las presentes Condiciones en función del
              domicilio en el que se encuentre el Cliente.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.9 Enmiendas
            </h3>
            <b-card-text data-v-35bd2db0="">
              Toda enmienda debe hacerse por escrito e indicar expresamente que se trata de una enmienda de las
              presentes Condiciones.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.10 Acuerdo completo
            </h3>
            <b-card-text data-v-35bd2db0="">
              Las presentes Condiciones y todoslos documentos a los que se hace mención o remisión en las mismas
              constituyen la totalidad del acuerdo entre las partes respecto a la materia objeto de las mismas y
              sustituyen a todos los acuerdos previos o actuales sobre dicha materia.
            </b-card-text>
            <h3 data-v-35bd2db0="">
              18.11 Interpretación de condiciones en conflicto
            </h3>
            <b-card-text data-v-35bd2db0="">
              En caso de existir algún conflicto entre las Condiciones y cualquier versión anterior de las mismas, y
              salvo que se indique de otro modo, prevalecerán las disposiciones de las presentes Condiciones.
            </b-card-text>
          </b-card-group>

          <b-card-text class="text-center  mt-3">
            <b-link :href="landingURL" target="_blank">
              <b-img :src="brand" alt="Powered by Nectios" class="brand" />
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BSpinner,
  BRow,
  BCol,
  BCard,
  BLink,
  BImg,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardGroup,
  BForm,
  BButton,
  VBTooltip,
  BIconGoogle,
  BIconLinkedin,
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BRow,
    BCol,
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BForm,
    BButton,
    BCardGroup,
    BImg,
    SafeImg,
    ValidationProvider,
    ValidationObserver,
    BIconGoogle,
    BIconLinkedin,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  data() {
    return {
      isLoading: true,
      community: null,
      text: null,

      from: null,
      path: null,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    email() {
      return `mailto:newsletter${this.community.mainCommunitySlug}.tech`;
    },
    signUpURL() {
      let url = process.env.VUE_APP_SIGN_UP_URL.replace('{subdomain}', this.community.subdomain || 'app').replace(
        '{slug}',
        this.communitySlug,
      );
      url = `${url}?code=${this.community.language}`;
      return url;
    },
    showRegistrationLink() {
      return this.community.accessibility < 2;
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    communityLogo() {
      return getImageResource(
        this.community.customization?.theme?.login?.logo || this.community.header?.logo_url || this.community.logoURL,
      );
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  created() {
    this.updateCommunity();
    this.fetchterms();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async fetchterms() {
      const response = await this.$store.$service[Service.BackendClient].get('communityCustomizations', {
        params: { communitySlug: this.communitySlug },
      });
      this.text = response.data.result.community_customizations.terms_conditions;
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get('login', {
        params: { communitySlug: this.communitySlug },
      });
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      /*if (this.community.showChatbot === 1) {
        const chatBot = document.createElement('script');
        chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
        document.body.appendChild(chatBot);
      }*/
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      if (this.community.mainCommunitySlug && this.community.mainCommunitySlug !== this.communitySlug) {
        this.from = this.communitySlug;
        this.path = this.$route.params.path;
        await this.$router.replace({
          name: 'auth-login',
          params: { communityId: this.community.mainCommunitySlug },
        });

        return;
      }

      this.isLoading = false;
      this.setLocale();
    },
    setLocale() {
      this.$store.dispatch('setLocale', this.community.language);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.login-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}
.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
@media (max-width: 767px) {
  .card {
    height: 100%;
    border-radius: 0;
  }
  .col {
    min-height: 100vh;
  }
}
</style>
